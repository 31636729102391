<template>
  <div :class="'banner--' + variant" class="banner">
    <span v-if="!isEmpty(content?.text)">{{ content?.text }}</span>
  </div>
</template>

<script lang="ts" setup>
import isEmpty from '@utils/isEmpty';

import type { Banner } from './models';

const { layout, content } = defineProps<Banner>();

const variant = computed(() => layout?.variant);
</script>

<style src="./Banner.scss" scoped lang="scss"></style>
