import type { MaybeRefOrGetter } from 'vue';
import type { BaseImage, ImxPlatformImage } from '@models/BaseImage';
import type { BaseVideo, ImxPlatformVideo } from '@models/BaseVideo';
import type { BaseFile, ImxPlatformFile } from '@models/BaseFile';
import type { Nullable } from '@models/CustomUtilityTypes';
import type { WhlModuleType } from '@models/WhlModuleType';

export default (
  data: MaybeRefOrGetter<
    Nullable<ImxPlatformImage | ImxPlatformVideo | ImxPlatformFile>
  >,
  fallbackWhlModuleType?: WhlModuleType
) => {
  const toBaseImage = computed(
    (): Nullable<BaseImage & { isFallback?: boolean }> => {
      const dataValue = toValue(data);
      if (isEmpty(dataValue) || !isImxPlatformImage(dataValue)) {
        return {
          deeplink: null,
          type: fallbackWhlModuleType,
          isFallback: true,
        };
      }
      return convertImxPlatformImage(dataValue);
    }
  );

  const toBaseVideo = computed((): Nullable<BaseVideo> => {
    const dataValue = toValue(data);
    if (isEmpty(dataValue) || !isImxPlatformVideo(dataValue)) {
      return null;
    }

    return convertImxPlatformVideo(dataValue);
  });

  const toBaseFile = computed((): Nullable<BaseFile> => {
    const dataValue = toValue(data);
    if (isEmpty(dataValue) || !isImxPlatformFile(dataValue)) {
      return null;
    }
    return null;
    //return convertImxPlatformFile(dataValue);
  });

  return { toBaseImage, toBaseVideo, toBaseFile };
};
