import type { ActiveHeadEntry, UseSeoMetaInput } from '@unhead/schema';
import type { WhitelabelAppConfig } from '@models/WhitelabelAppConfig';
import { WhitelabelAppType } from '@models/WhitelabelAppType';

export default (
  input: UseSeoMetaInput,
  options?: unknown
): ActiveHeadEntry<unknown> | undefined => {
  const appConfig = useWhlAppConfig() as WhitelabelAppConfig;

  if (appConfig?.appType === WhitelabelAppType.PORTAL) {
    const entry = useSeoMeta(input, options);
    return entry || undefined;
  } else {
    // do nothing in widget mode
    return undefined;
  }
};
