export enum ErrorMessage {
  NO_TRANSLATION_CONTEXT = 'No translation context provided',
  NO_DATA = 'No data provided',
  NOT_FOUND = 'The requested resource was not found',
  UNKNOWN_ERROR = 'An unexpected error occurred',
  NO_ID = 'Id is required',
  NO_PERMALINK = 'Permalink is required',
  NO_FILTER = 'No filter provided',
  NO_CONSENT_MANAGER = 'No consent manager provided',
}
