import type { ImxPlatformFile } from '@models/BaseFile';
import type { ImxPlatformImage } from '@models/BaseImage';
import type { ImxPlatformVideo } from '@models/BaseVideo';
import type { Nullable } from '@models/CustomUtilityTypes';

const POSSIBLE_TYPES = [
  'AddressbaseImage',
  'ArticleImage',
  'OfferImage',
  'EventImage',
];

export default (
  data: MaybeRefOrGetter<
    Nullable<ImxPlatformImage | ImxPlatformVideo | ImxPlatformFile>
  >
): data is ImxPlatformImage => {
  const dataValue = toValue(data);
  return (
    typeof dataValue === 'object' &&
    !isEmpty(dataValue) &&
    !!dataValue?.__typename &&
    POSSIBLE_TYPES.includes(dataValue.__typename)
  );
};
